:root {
  --border-radius-tiny: 0.4rem;
  --border-radius-small: 0.6rem;
  --border-radius-medium: 1rem;
  --border-radius-large: 2rem;

  --box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19);
  --box-shadow--hover: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --box-shadow-subtle: 1px 2px 3px rgba(0, 0, 0, 0.15);

  --border-black: 1px solid black;
  --border-grey: 1px solid var(--color-grey-light);
  --border-grey-light: 1px solid #d6d6d6;
}
