.alert-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 100000;
}

.alert {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  margin-bottom: 5rem;
  min-width: 30rem;
  padding: 1.2rem 2.4rem;
  pointer-events: none;
  text-align: center;
  font-size: 1.6rem;
  background-color: var(--color-alert);
  border-radius: var(--border-radius-medium);
  color: var(--color-font) !important;
  box-shadow: var(--box-shadow);

  &--error {
    background-color: var(--color-alert--error);
  }

  @media only screen and (min-width: 800px) {
    font-size: 1.8rem;
  }
}
