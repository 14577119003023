.loading-page {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
  row-gap: 2rem;

  & img {
    width: 20rem;
  }
}
