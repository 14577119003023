.sv_qstn .sq-root {

  padding: 20px;
  margin-bottom: 30px;
}
.sv_qstn .sq-root-cb {
  border-left: 4px solid orange;
}
.sq-title {
  font-size: 30px;
  text-align: center;
}
.sq-title-required {
  color: red;
}
.sq-label {
  margin-left: 30px;
}
.sq-item {
  //margin-bottom: 10px;
  //border: 2px solid #8ad0de;
  //border-radius: 40px;
  //text-align: center;
  //display: inline-block;
  //padding: 8px 14px;
  //cursor: pointer;
  //width: 10%;

}
.sq-title-des {
  text-align: center;
  color: #808080;
}