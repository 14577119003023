.emergency {
  background-color: var(--color-blue-solitude);
  width: 64rem;
  border-radius: var(--border-radius-large);
  padding: 3rem;
  z-index: 4;
  display: grid;
  position: relative;
  font-size: 1.4rem;
  grid-template-columns: 1fr max-content;
  padding-top: 5rem;

  a {
    color: var(--color-blue-steel);

    &:hover {
      color: var(--color-blue-eastern);
    }
  }

  @media only screen and (max-width: 700px) {
    width: 64rem;
  }

  @media only screen and (max-width: 500px) {
    width: 58rem;
  }

  @media only screen and (max-width: 450px) {
    width: 52rem;
  }

  @media only screen and (max-width: 400px) {
    width: 46rem;
  }

  &__item {
    grid-column: 1 / -1;

    @media only screen and (min-width: 700px) {
      &:nth-child(4) {
        grid-column: 1 / 2;
      }
      &:nth-child(5) {
        grid-column: 2 / 3;
        padding-right: 2rem;
      }
    }
  }

  &__title {
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}
