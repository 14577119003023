.item {
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  padding: 1rem 2rem 2rem;
  background-color: var(--color-white);
  border-radius: var(--border-radius-small);
  animation: fadeup 0.7s;

  &--list {
    box-shadow: var(--box-shadow);
    transition: box-shadow 0.3s;
    width: 80rem;

    &:hover {
      box-shadow: var(--box-shadow--hover);
    }
  }

  &--popup {
    width: 60rem;
    min-height: 24rem;
    animation: none;
  }

  &__img,
  &__details {
    margin-right: 2rem;
  }

  &__container {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  &__img {
    grid-column: 1 / 4;
    height: 12rem;
    width: 20rem;
    object-fit: cover;
    align-self: center;
    border-radius: var(--border-radius-medium);
    float: left;
  }

  &__details {
    grid-column: 1 / 3;
    display: grid;
  }

  &__title {
    @extend .text-primary--bold;
  }

  &__title_AMS {
    @extend .text-primary--bold;
  }

  &__description {
    @extend .text-secondary;
    grid: 1 / 2;
  }

  &__read-more {
    color: var(--color-blue-steel);
    font-size: 1.6;
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 5px;
  }

  &__note {
    @extend .text-tertiary;
  }

  &__options {
    grid-column: 3 / 4;
    display: grid;
    align-content: flex-start;
    row-gap: 1rem;
    width: max-content;
    min-width: 16rem;
  }

  &__option {
    display: flex;
    justify-self: start;
    align-items: center;
    grid-template-columns: repeat(1, max-content);
    column-gap: 1rem;
    cursor: pointer;

    span {
      font-size: 1.4rem;
      color: var(--color-blue-steel);
    }
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    fill: var(--color-blue-steel);
  }

  &__heart {
    align-self: center;
    fill: white;
    stroke: var(--color-blue-steel);
    height: 2.6rem;
    width: 3.2rem;
    stroke-width: 2px;

    &--filled {
      fill: var(--color-blue-steel);
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 801px) {
    &--filters {
      width: 64rem;
    }
  }

  // Takes up all but a few rem on each side
  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
    padding: 2rem;
    row-gap: 1rem;

    & > * {
      grid-column: 1 / 2;
    }

    &__img {
      grid-row: 1 / 2;
      height: 15rem;
      width: 25rem;
      justify-self: center;
    }

    &__details {
      grid-row: 2 / 3;
    }

    &__options {
      margin-top: 1rem;
      grid-row: 3 / 4;
      display: grid;
      grid-template-columns: max-content 1fr;
      column-gap: 2rem;
      justify-self: center;
    }
  }

  @media only screen and (max-width: 400px) {
    &__options {
      margin-top: 0;
      grid-template-columns: 1fr;
    }
  }

  // ONLY FOR ITEMS WITH THUMBNAILS (ONDEMAND)
  @media only screen and (max-width: 1100px) and (min-width: 801px),
    only screen and (max-width: 700px) and (min-width: 501px) {
    &--filters.item--image {
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;

      & .item__details {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
      }

      & .item__img {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }

      & .item__options {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }
    }
  }
}

.item-confirmation {
  background-color: var(--color-blue-solitude);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  padding: 4rem 6rem 2rem;
  height: max-content;

  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4rem;
  column-gap: 4rem;
  grid-template-rows: max-content 4rem;

  &__message {
    grid-column: 1 / -1;
  }
}

.social-inline-flex {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.social-inline-flex .item__option {
  display: inline-block;
  margin-right: 10px;
}

.social-inline-flex .item__option h4 {
  margin: 0px;
}

.social-inline-flex .item__option h4 svg {
  width: 25px;
  height: 25px;
}

.item__description {
  text-align: justify;
}

// .none-d {
//   display: none;
// }
