.heading-primary {
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--color-font) !important;
}

.heading-secondary {
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--color-font) !important;
}

.heading-tertiary {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--color-font) !important;
}
