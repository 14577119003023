.favourites {
  width: 96vw; // quick fix for 100vw making it horizontal scrollable
  margin: 0 auto;

  &__title {
    text-align: center;
    margin: 2rem 1.2rem 2rem;
  }

  & .ant-tabs-nav-wrap {
    justify-content: center;
  }
  @media only screen and (max-width: 900px) {
    & .ant-tabs-nav-wrap {
      justify-content: flex-start;
    }
    & .ant-tabs-nav-list {
      padding: 0 3rem;
    }
  }

  & .ant-tabs-tab {
    color: black;
    font-size: 1.6rem;

    &:hover,
    &:active,
    &-active,
    &:focus {
      color: var(--color-blue-steel) !important;
    }
  }

  & .ant-tabs-ink-bar {
    background: var(--color-blue-steel) !important;
  }
}
