.class-page {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: min-content min-content max-content;
  align-content: start;
  padding: 2rem 6vw;
  row-gap: 2rem;
  column-gap: 2rem;

  &__title {
    grid-column: 1 / -1;
    justify-self: center;
    @extend .heading-secondary;
  }

  &__buttons {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    justify-items: start;
  }

  &__button {
    &--prev {
      grid-column: 1 / 2;
      margin-right: 1rem;
    }
    &--today {
      grid-column: 2 / 3;
    }
    &--next {
      grid-column: 3 / 4;
    }
  }

  &__day {
    display: grid;
    row-gap: 1rem;
    align-content: start;

    &:not(:last-child) {
      .class-page__classes {
        border-right: var(--border-black);
      }
    }
  }

  &__date {
    @extend .heading-tertiary;
    width: max-content;
    justify-self: start;
  }

  &__classes {
    min-height: 40rem;
  }

  &__loading {
    justify-self: center;
    height: 10rem;
    margin-top: 4rem;
  }
}

.class-item {
  animation: fadein 0.7s;
  cursor: pointer;
  margin-right: 0.6rem;
  display: grid;

  &__title {
    @extend .text-primary--bold;
  }

  &--past {
    pointer-events: none;
    cursor: default;

    & .class-item__title,
    & .class-item__info {
      color: lightgray;
    }
  }

  &__icon {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
    object-fit: cover;
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .class-page {
    padding: 2rem 4vw;
  }
}

@media only screen and (max-width: 1000px) {
  .class-page {
    padding: 2rem 1.5vw;
    column-gap: 1.4rem;
  }
}

@media only screen and (max-width: 800px) {
  .class-page {
    padding: 2rem 4rem;
    grid-template-columns: 1fr;
    row-gap: 0;

    &__buttons,
    &__title {
      margin-bottom: 1rem;
    }

    &__day {
      justify-self: center;
      justify-items: center;
      margin-bottom: 0.4rem;
    }

    &__date {
      justify-self: center;
    }

    &__classes {
      border: none !important;
      min-height: 0;
    }
  }

  .class-page__day,
  .class-item {
    &--past {
      display: none;
      margin-bottom: 0;
    }
  }

  .class-item {
    width: 40rem;
    margin-bottom: 1rem;
    padding: 1rem;

    grid-template-columns: 1fr max-content;
    border-radius: var(--border-radius-small);
    box-shadow: var(--box-shadow);
    transition: box-shadow 0.3s;

    &__icon {
      display: block;
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }

    &:hover {
      box-shadow: var(--box-shadow--hover);
    }
  }
}

@media only screen and (max-width: 500px) {
  .class-item {
    width: 80vw;
  }
}
