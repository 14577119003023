$wavey-image: "../../imgs/util/wavey.png";

.message-bar {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  background-image: url($wavey-image);
  background-size: cover;
  padding: 3rem 12rem;

  @media only screen and (max-width: 1000px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 3rem;
  }

  &__text {
    justify-self: center;
    text-align: center;
    grid-row: 1/2;
    grid-column: 1 /2;

    @media only screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
}
