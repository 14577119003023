.language-toggle {
  line-height: 1;
  cursor: pointer;
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;

  @media only screen and (max-width: 1000px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.6rem;
  }
}
