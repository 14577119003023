.custom-drawer {
  &__cross {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    fill: var(--color-black);
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  &,
  & .ant-drawer-wrapper-body,
  & .ant-drawer-body {
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  & .ant-drawer-mask {
    background-color: var(--color-modal-mask) !important;
  }
}
