.profile {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 64rem;
  background-color: var(--color-blue-solitude);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  padding: 3rem;

  &__logo {
    margin-top: 3rem;
    height: 16rem;
    width: 17rem;
  }

  &__button {
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius-small);
    background-color: var(--color-blue-eastern);
    color: var(--color-white);
    box-shadow: var(--box-shadow);
    font-size: 1.8rem;
    padding: 0.4rem 1rem;
    transition: box-shadow 0.3s;
    min-width: 10rem;
    width: max-content;

    &:hover {
      box-shadow: var(--box-shadow--hover);
    }

    &--saved,
    &--saving {
      background-color: var(--color-grey-light);
    }

    &--saving {
      pointer-events: none;
    }
  }

  &__inputs {
    padding: 3rem 0 3rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(3, max-content);
    align-items: center;
    column-gap: 4rem;
    row-gap: 2rem;
    font-size: 1.8rem;

    input,
    select {
      border-radius: var(--border-radius-small);
      border: var(--border-grey);
      padding: 0.3rem 1.4rem;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: max-content;
    padding: 5rem;
    &__inputs {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 96%;
    margin: 0 auto;

    &__logo {
      margin-top: 1rem;
      height: 14rem;
      width: 14.6rem;
    }

    &__inputs {
      font-size: 1.8rem;
      column-gap: 1rem;
    }
  }
}
