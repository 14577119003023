.footer {
  display: grid;
  grid-template-columns: 1fr max-content;
  box-shadow: var(--box-shadow-subtle);
  padding: 1rem 2rem 1.2rem;
  align-items: center;
  background-color: var(--color-blue-solitude);

  &--white {
    background-color: var(--color-white);
  }

  &__image {
    width: 20rem;
  }

  &__details {
    text-align: end;
  }

  &__email {
    color: var(--color-font);
    text-decoration: underline;
    transition: all 0.3s;

    &:hover {
      color: #1890ff;
      text-decoration: underline;
      text-decoration-color: #1890ff;
    }
  }

  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 2rem;

    &__details {
      display: grid;
      width: 100%;
    }

    &__text {
      text-align: center;
    }

    &__image {
      margin-bottom: 1rem;
    }
  }
}
