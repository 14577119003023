.button {
  &--blue,
  &--red,
  &--white {
    text-transform: none;
    border: none;
    cursor: pointer;
    color: var(--color-font) !important;
    border-radius: var(--border-radius-small);
    font-size: 1.8rem;
    box-shadow: var(--box-shadow-subtle);
    transform: box-shadow 0.3s;
    padding: 0.8rem 1.2rem;
    font-size: 1.8rem;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: var(--box-shadow);
    }
  }

  &--blue {
    background-color: var(--color-blue-light);
  }

  &--white {
    background-color: var(--color-white);
  }

  &--red {
    background-color: var(--color-red-button);
  }

  // Order of these items are important --nav and --profile
  &--nav {
    height: 3.6rem;
    display: grid;
    align-items: center;
    padding: 0 1.2rem;
  }

  &--profile {
    padding: 0 0.4rem;
  }
}

.circle-button {
  height: 11rem;
  width: 11rem;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-blue-royal);
  border: 2px solid var(--color-white);
  font-size: 1.8rem;
  font-weight: 700;
  display: grid;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  transition: border 0.3s;

  &:focus,
  &:hover {
    border: 2px solid var(--color-grey);
  }

  &--loading {
    background-color: var(--color-blue-royal);
    cursor: default;
    border: 2px solid var(--color-white) !important;
  }

  &--disabled {
    background-color: var(--color-grey);
    cursor: default;
    pointer-events: none;
  }

  &--small-text {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 800px) {
    height: 13rem;
    width: 13rem;
    font-size: 2rem;

    &--small-text {
      font-size: 1.5rem;
    }
  }
}
