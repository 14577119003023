.QuizStartPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container{
    width: 60px;
    height: 60px;
  }

  .container img{
    width: 100%;
    height: auto;
  }

  &__button{
    background-color: red;
  }
}