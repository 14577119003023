@import "../../styles/typography/headings";

.calendar {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-columns: min-content 1fr;
  row-gap: 2rem;

  // Override Important Ant Design Effects
  line-height: 1.15;

  &__title {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    justify-self: center;
  }

  &__filter-button {
    display: none;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    justify-self: start;
    align-self: center;
  }

  &__filters {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  &__display {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  @media only screen and (max-width: 800px) {
    &__filters {
      display: none;
    }

    &__filter-button {
      display: grid;
    }
  }
  // Calendar
  .fc-toolbar-title {
    font-size: 2.4rem !important;
    font-weight: 500;
    color: var(--color-calendar);
  }

  .fc-event-time, .fc-event-title {
    padding-right: 3px;
  }

  .fc-prev-button,
  .fc-next-button,
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-today-button {
    border: none !important;
    cursor: pointer;
    box-shadow: var(--box-shadow-subtle);
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: var(--box-shadow);
      z-index: 2;
    }
  }

  .fc-prev-button,
  .fc-next-button,
  .fc-dayGridMonth-button {
    background-color: var(--color-blue-light) !important;
    color: var(--color-font) !important;
    padding: 0.4rem 1.2rem !important;
    font-size: 1.8rem !important;
  }
  .fc-timeGridWeek-button,
  .fc-today-button {
    background-color: var(--color-grey) !important;
    padding: 0.4rem 1.2rem !important;
    font-size: 1.8rem !important;
  }

  // All Fonts On Calendar
  // .fc-view-harness {
  //   & * {
  //     color: var(--color-blue-solitude) !important;
  //   }
  // }

  // Color of dot for event
  // .fc-daygrid-event-dot {
  //   border-color: var(--color-blue-solitude) !important;
  // }

  // Color of Events
  // .fc-timegrid-event {
  //   background-color: var(--color-blue-royal) !important;
  //   border-color: var(--color-blue-royal) !important;
  //   transition: all 0.3s;

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     background-color: var(--color-blue-steel) !important;
  //     border-color: var(--color-blue-steel) !important;
  //   }
  // }

  // Background of today highlight
  // .fc .fc-daygrid-day.fc-day-today {
  //   background-color: var(--color-grey-light) !important;
  // }

  @media only screen and (max-width: 700px) {
    &__filter-button {
      grid-row: 2 / 3;
      align-self: start;
      z-index: 4;
    }

    .fc-daygrid-day-number {
      font-size: 1.8rem !important;
    }

    .fc-header-toolbar {
      display: grid !important;
      grid-template-columns: max-content 1fr;
      grid-template-rows: max-content max-content;

      .fc-toolbar-title {
        font-size: 2.6rem !important;
        padding-bottom: 1.8rem;
      }

      .fc-toolbar-chunk {
        &:nth-child(2) {
          justify-self: center;
          grid-column: 1 / -1;
        }

        &:nth-child(1),
        &:nth-child(3) {
          grid-row: 2 / 3;
        }

        &:nth-child(3) {
          justify-self: end;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .fc-toolbar-title {
      font-size: 2.2rem !important;
    }

    .fc-dayGridMonth-view .fc-scrollgrid-sync-table {
      height: 50rem !important;
    }

    .fc-daygrid-day-top {
      line-height: 1 !important;
    }

    .fc-daygrid-day-events {
      font-size: 1.8rem !important;
    }
  }
}
