.custom-modal {
  &__cross {
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  & .ant-modal-close {
    display: none;
  }

  & .ant-modal-body {
    width: 100%;
  }

  &,
  & .ant-modal-body,
  & .ant-modal-content {
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
