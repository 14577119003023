.ams-clubs {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    padding: 4rem 12rem;

    @media only screen and (max-width: 1000px) {
      padding-left: 8rem;
      padding-right: 8rem;
    }

    @media only screen and (max-width: 800px) {
      padding: 4rem;
    }
  }
}

.tab-container {
  display: grid;
  justify-items: center;
  row-gap: 4rem;
  padding: 4rem 0;
  background-size: cover;
  width: 100%;
}

.tab {
  &__info {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    align-items: center;
    padding: 0 10vw;
    column-gap: 4rem;
    row-gap: 1rem;
    width: 100%;
  }

  &__img {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
    width: 10rem;
    height: 9rem;

    @media only screen and (max-width: 600px) {
      width: 8rem;
      height: 7.2rem;
    }
  }

  &__description {
    font-size: 1.6rem;
    color: var(--color-grey);
  }

  &__button {
    text-transform: none;
    border: none;
    background-color: var(--color-blue-eastern);
    border-radius: var(--border-radius-medium);
    font-size: 1.6rem;
    color: var(--color-white);
    padding: 1rem 2rem;
    cursor: pointer;
    box-shadow: var(--box-shadow-subtle);
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: var(--box-shadow);
    }
  }
}
