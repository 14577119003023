.fitness {
  display: grid;
  justify-content: center;
  align-content: start;
  padding: 3rem;

  &__title {
    width: 100%;
    text-align: center;
    padding-bottom: 3rem;
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
    column-gap: 3rem;
    row-gap: 3rem;
    max-width: 100rem;

    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      row-gap: 2rem;
      max-width: 60rem;
    }
  }
}

.fitness-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-solitude);
  padding: 4rem 12rem;

  @media only screen and (max-width: 1000px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 4rem;
  }
}

.fitness-block {
  display: grid;
  grid-template-rows: max-content 8rem max-content;
  justify-items: center;

  padding: 3rem 6rem 1rem;
  border-radius: var(--border-radius-large);
  background-color: var(--color-blue-solitude);
  min-height: 24rem;
  box-shadow: var(--box-shadow-subtle);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: var(--box-shadow);
  }

  &__title {
    text-align: center;
  }

  &__desc {
    text-align: center;
  }

  &__img {
    text-align: center;
    align-self: center;
    width: 100%;
    margin-bottom: 0.5rem;

    & > img {
      height: 5.6rem;
    }
  }
}
