:root {
  --color-grey-light: #b3b3b3;
  --color-grey: #727272;
  --color-grey-dark: #575757;
  --color-grey-darker: #707070;

  --color-font: #424242;
  --color-calendar: #1890ff;

  --color-black: #000000;
  --color-white: #ffffff;

  --color-blue-light: #e7f5ff;
  --color-blue-solitude: #ecf4fe;
  --color-blue-steel: #3f88ae;
  --color-blue-eastern: #148ab2;
  --color-blue-royal: #3870e0;

  --color-blue-buttons: #3870e0;

  --color-filter--1: #a3d485;
  --color-filter--2: #ca7fd1;
  --color-filter--3: #8b7fd3;

  --color-red-button: #fa6767;

  --color-alert: #ecf4fe;
  --color-alert--error: #ff0000;

  --color-grey-dominant: #424242;

  --color-modal-mask: rgba(0, 0, 0, 0.25);
}
